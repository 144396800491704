import * as auth0 from 'auth0-js';
import * as moment from 'moment';
import history from '../../history';

import { store } from '../../store';
import { getEnvironment } from '../../utils/envResolver';
import { fetchSavedDocumentsRequest } from '../Saved/actions';
import { clearUserProfile, setUserProfile } from '../User/actions';
import { logError } from '../../utils/logger';

const env = getEnvironment();
let clientId = '';
let redirectUri = '';
let domain = '';
const namespace = process.env.REACT_APP_AUTH0_NAMESPACE || 'https://stg.spinba.se';
let audience = '';
const trialLengthInDays: number = Number(process.env.REACT_APP_TRIAL_PERIOD_TIME) || 14;
const trialEndingWarningLengthInDays: number = Number(process.env.REACT_APP_TRIAL_PERIOD_ENDING_TIME) || 3;

if (env === 'dev') {
  audience = 'https://dev.spinba.se';
  clientId = 'Fr8lzPmGieEMi5hVgSZ3f9zdSm3A2CyY';
  redirectUri = 'https://dev.spinba.se/callback';
  domain = 'spinbase-dev.eu.auth0.com';
}

if (env === 'stg') {
  audience = 'https://stg.spinba.se';
  clientId = 'ybMZ0rDu07EzB04BqhsaXsqqgNIBtPhC';
  redirectUri = 'https://stg.spinba.se/callback';
  domain = 'spinbase-stg.eu.auth0.com';
}

if (env === 'prod') {
  audience = 'https://spinba.se';
  clientId = '1Vusadd06mIwOB7hYOc98r4MZcEZu9VG';
  redirectUri = 'https://spinba.se/callback';
  domain = 'spinbase.eu.auth0.com';
}

const auth = new auth0.WebAuth({
  audience: process.env.REACT_APP_AUTH0_AUDIENCE || audience,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || clientId,
  domain: process.env.REACT_APP_AUTH0_DOMAIN || domain,
  redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI || redirectUri,
  responseType: 'token id_token',
  scope: 'openid permissions profile groups roles email',
});

export const login = (loginHint: string = '') => {
  auth.authorize({ login_hint: loginHint });
};

export let userProfile: auth0.Auth0UserProfile;
let tokenRenewalTimeout: any;

export const getProfile = (cb?: any): void => {
  const accessToken = getAccessToken();
  if (accessToken) {
    auth.client.userInfo(accessToken, (err: any, profile: any) => {
      if (profile) {
        userProfile = profile;
        store.dispatch(setUserProfile(profile));
        store.dispatch(fetchSavedDocumentsRequest());
      }
      if (cb) {
        cb(err, profile);
      }
    });
  }
};

export const handleAuthentication = () => {
  auth.parseHash(async (err, authResult) => {
    if (authResult && authResult.accessToken && authResult.idToken) {
      setSession(authResult);
      getProfile(() => {
        if (isFirstLogin()) {
          history.push('/license-agreement');
          return;
        }
      });
      history.push('/search');
    } else if (err) {
      history.push('/search');
      logError(err);
    }
  });
};

export const getAccessToken = () => {
  const accessToken = localStorage.getItem('access_token');
  if (!accessToken) {
    return '';
  }
  return accessToken;
};

export const setSession = (authResult: any) => {
  const scopes = authResult.scope || '';
  const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + moment().valueOf());
  localStorage.setItem('access_token', authResult.accessToken);
  localStorage.setItem('id_token', authResult.idToken);
  localStorage.setItem('expires_at', expiresAt);
  localStorage.setItem('scopes', JSON.stringify(scopes));
  scheduleRenewal();
};

export const logout = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token');
  localStorage.removeItem('expires_at');
  localStorage.removeItem('scopes');

  userProfile = {
    clientID: '',
    created_at: '',
    identities: [],
    name: '',
    nickname: '',
    picture: '',
    sub: '',
    updated_at: '',
    user_id: '',
    email_verified: false,
  };

  auth.logout({});

  store.dispatch(clearUserProfile());
  clearTimeout(tokenRenewalTimeout);
  history.push('/');
};

export const renewToken = () => {
  auth.checkSession({}, (err, result) => {
    if (err) {
      logError(err);
    } else {
      setSession(result);
    }
  });
};

export const scheduleRenewal = () => {
  const expiresAt = JSON.parse(localStorage.getItem('expires_at') as string);
  const delay = expiresAt - Date.now();
  if (delay > 0) {
    tokenRenewalTimeout = setTimeout(() => {
      renewToken();
    }, delay);
  }
};

export const isAuthenticated = () => {
  const expiresAt = JSON.parse(localStorage.getItem('expires_at') || '0');
  return moment().valueOf() < expiresAt;
};

const getClaimUrl = () => {
  return `${namespace}/claims/`;
};

export const isAuthorizationBasedOnUserList = () => {
  return hasRole('LIST_BASED_AUTHORIZED') || hasRole('LIST_BASED_UNAUTHORIZED');
};

export const isUserAuthorizedBasedOnList = () => {
  return hasRole('LIST_BASED_AUTHORIZED');
};

export const isUserUnAuthorizedBasedOnList = () => {
  return hasRole('LIST_BASED_UNAUTHORIZED');
};

export const canUseSearch = () => {
  const isTrialUserNotVerified: boolean = isTrialUser() && !isEmailVerified();
  const trialBasedPermission = !hasTrialExpired() && !isTrialUserNotVerified;
  const listBasedAuthorized = isUserAuthorizedBasedOnList() && !isUserUnAuthorizedBasedOnList();
  return (
    hasPermission('use:search') &&
    (isAuthorizationBasedOnUserList() ? listBasedAuthorized || !isTrialUser() : trialBasedPermission)
  );
};

export const hasPermission = (permission: string) => {
  if (!userProfile) {
    return false;
  }
  // @ts-ignore
  return userProfile[`${getClaimUrl()}permissions`].some((val: string) => val === permission);
};

export const hasRole = (role: string) => {
  if (!userProfile) {
    return false;
  }
  // @ts-ignore
  return userProfile[`${getClaimUrl()}roles`].some((val: string) => val === role);
};

export const trialWillExpire = () => {
  return isTrialUser() && getTrialDaysLeft() < trialEndingWarningLengthInDays + 1;
};

export const hasTrialExpired = () => {
  const isTrial: boolean = isTrialUser();
  const trialTimePassed: boolean = getTrialDaysLeft() < 1;
  return isTrial && trialTimePassed;
};

export const getTrialDaysLeft = (): number => {
  if (!userProfile) {
    return 0;
  }
  const currentDate = moment();
  // @ts-ignore
  const userCreatedAt = moment(userProfile[`${getClaimUrl()}created_at`]);
  return trialLengthInDays - currentDate.diff(userCreatedAt, 'days');
};

export const isTrialUser = (): boolean => {
  const hasTrialRole: boolean = hasRole('Trial User');
  const hasPaidRole: boolean = hasRole('User') || hasRole('Admin');
  return hasTrialRole && !hasPaidRole;
};

export const isEmailVerified = (): boolean => {
  return userProfile.email_verified || false;
};

export const getLoginCount = (): number => {
  // @ts-ignore
  return userProfile[`${getClaimUrl()}logins`];
};

export const isFirstLogin = (): boolean => {
  return getLoginCount() === 1;
};
